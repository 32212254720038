<template>
  <v-container fluid class="pr-0">
    <v-card flat :class="$vuetify.breakpoint.mobile ? '' : 'pa-2'">
      <v-form ref="form">    
        <v-row justify="center">
          <v-card width="100%" min-width="200" flat>
            <v-row no-gutters>
              <template v-for="(pergunta, i) in perguntas">
                <v-col
                  :cols="pergunta.config.cols" 
                  :md="pergunta.config.md"
                  :align-self="pergunta.config.align"
                  :offset="pergunta.config.offset"
                  :key="i"
                >
                  <component
                    :is="pergunta.tipoPergunta.nomeComponent"
                    :key="`component-${pergunta.tipoPergunta.code.toLowerCase()}`"
                    v-bind="{ ...pergunta.props }"
                  ></component>
                </v-col>
              </template>
            </v-row>
          </v-card>
        </v-row>     
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import RespostaCurtaView from "@/components/forms/RespostaCurtaView.vue";
import RespostaTextoView from "@/components/forms/RespostaTextoView.vue";
import RespostaVerdadeiroFalsoView from "@/components/forms/RespostaVerdadeiroFalsoView.vue";
import RespostaDataView from "@/components/forms/RespostaDataView.vue";
import RespostaUnicaView from "@/components/forms/RespostaUnicaView.vue";
import RespostaMultiplaView from "@/components/forms/RespostaMultiplaView.vue";
import RespostaAudioView from "@/components/forms/RespostaAudioView.vue";
import RespostaArquivoView from "@/components/forms/RespostaArquivoView.vue";
import RespostaImagemView from "@/components/forms/RespostaImagemView.vue";
import RespostaVideoView from "@/components/forms/RespostaVideoView.vue";
import RespostaSubtituloCreateEdit from "@/components/forms/RespostaSubtituloCreateEdit.vue";
import RespostaEditorView from "@/components/forms/RespostaEditorView.vue";
import RespostaLegendaCreateEdit from "@/components/forms/RespostaLegendaCreateEdit.vue";

export default {
  name: "formulario-exame-view",
  props: {
    id: {
      type: String,
      default: undefined,
    },
    storageNameParam: {
      type: String,
      default: undefined,
    },
    formularioParam: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return { 
      storageName: null,
      formulario: {},
      perguntas: [],
      perguntasOriginais: [],
      isModeloForms: false,
    };
  },
  components: {
    RespostaCurtaView,
    RespostaTextoView,
    RespostaVerdadeiroFalsoView,
    RespostaDataView,
    RespostaUnicaView,
    RespostaMultiplaView,
    RespostaAudioView,
    RespostaArquivoView,
    RespostaImagemView,
    RespostaVideoView,
    RespostaSubtituloCreateEdit,
    RespostaEditorView,
    RespostaLegendaCreateEdit,
  },
  methods: {
    defineComponentInstance(nomeComponent) {
      return nomeComponent.replace("forms/", "").replace("audio/", "");
    },
    prepareFormulario(response){
      const self = this;
      self.formulario = JSON.parse(JSON.stringify(response.data))
      
      self.storageName = self.storageNameParam;
      if (self.formulario.unidade && self.formulario.unidade.storage){
        self.storageName = self.formulario.unidade.storage;
      }
      
      if (!(self.storageName && self.storageName.length > 3)){       
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Por favor, contate o Administrador.\n \nA unidade não possui storage configurado!",
          timeoutAlerta: 20000
        });
      } else {
        // Copia as perguntas e respostas originais para tratamento de exclusão dos arquivos do storage.
        self.perguntasOriginais = response.data.perguntas.map(p => ({...p}));
        self.perguntas = Object.assign([], response.data.perguntas).sort((a, b) => a.ordem - b.ordem);

        self.perguntas.forEach((p) => {
          // Tratamento para renderização dos componentes - transformação de respostas   
          switch(p.tipoPergunta.code) {
            case 'IMAGEM': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null} 
              break;
            case 'VIDEO':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'ARQUIVO':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'AUDIO':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'CURTA': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'TEXTO': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'VERDADEIRO_FALSO': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'DATA': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'UNICA_ESCOLHA':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'MULTIPLA_ESCOLHA':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'EDITOR':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'LEGENDA':
              p.config = {cols: 12, md: p.sizeMd, align: "start", offset: "0", valor: null}
              break;
            default:
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
          }

          // Renderiza o componente de acordo com o código
          p.tipoPergunta.nomeComponent = self.defineComponentInstance(p.tipoPergunta.nomeComponent).replace('CreateEdit','View');

          // Tratamento para definição do modelo de exibição [Hard Code - Inicial]
          p.showSelectionBoxes = p.modeloExibicao == "CS" ? true : false

          // Tratamento para visualização de respostas quando já existirem
          if(p.respostas) {
            let perguntaValores = (p.perguntaValores && p.perguntaValores.length > 0) ? p.perguntaValores.sort((p1, p2) => {
              return p1.ordem - p2.ordem;
            }) : null

            // Tratamento para renderização dos componentes - transformação de respostas       
            switch(p.tipoPergunta.code) {
              case 'IMAGEM': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, storageName: self.storageName, valor: p.respostas}
                break;
              case 'VIDEO':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, storageName: self.storageName, valor: p.respostas}
                break;
              case 'ARQUIVO':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, storageName: self.storageName, valor: p.respostas}
                break;
              case 'AUDIO':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, storageName: self.storageName, valor: p.respostas}
                break;
              case 'CURTA': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, valor: p.respostas.valor}
                break;
              case 'TEXTO': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, valor: p.respostas.valor}
                break;
              case 'VERDADEIRO_FALSO': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, valor: p.respostas.valor}
                break;
              case 'DATA': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, valor: p.respostas.valor}
                break;
              case 'UNICA_ESCOLHA':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, showSelectionBoxes: p.showSelectionBoxes, valor: p.respostas}
                break;
              case 'MULTIPLA_ESCOLHA':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, showSelectionBoxes: p.showSelectionBoxes, valor: p.respostas}
                break;
              case 'EDITOR':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, showSelectionBoxes: p.showSelectionBoxes, valor: p.respostas.valor}
                break;
              case 'LEGENDA':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, showSelectionBoxes: p.showSelectionBoxes, valor: p.respostas}
                break;
              default:
                p.props = {labelTitle: p.descricao}
            }

          } else {
            let perguntaValores = (p.perguntaValores && p.perguntaValores.length > 0) ? p.perguntaValores.sort((p1, p2) => {
              return p1.ordem - p2.ordem;
            }) : null
            p.props = { labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, inLineText: true, showSelectionBoxes: p.showSelectionBoxes }
          }
        });
      }
    },
    async loadModel() {
      const self = this

      let response = {
        status: 200,
        message: "OK"
      }

      if (self.formularioParam) {
        if (!self.formularioParam.modeloFormulario) {
          self.isModeloForms = true
        }
        response = { ...response, data: self.formularioParam }
      } else {
        response = await self.$http.get(`/forms/${self.id}`).then(resp => {
          self.isModeloForms = false
          return resp
        }).catch(async () => {
          return await self.$http.get(`/modeloForms/${self.id}`).then(resp => {
            self.isModeloForms = true
            return resp
          }).catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Formulário / Modelo não encontrado",
            })
          })
        })
      }
      
      if (response.data) self.prepareFormulario(response)
    },
  },
  created() {
    this.loadModel();
  },
}
</script>
