<template>
  <v-container class="ma-0 pa-0">
    <v-card flat>
      <v-card-title class="headline primary--text">
        <v-btn icon @click="closeExamesAnteriores">
          <v-icon large>mdi-chevron-right</v-icon>
        </v-btn>
        <div> Exames Anteriores </div>
      </v-card-title>
      <v-card-subtitle>
        <div>{{ $_.startCase(exame.solicitacao.paciente.nome.toLowerCase()) }}</div> 
        <div>{{ exame.data }}</div>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-virtual-scroll
        :items="exames"
        :item-height="50"
        height="300"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar
                color="primary"
                size="56"
                class="white--text"
              >{{ `${item.tipoExame.modalidade.sigla}`}}</v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.data }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.tipoExame.nome }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="openExameDialog(item)">
                <v-icon color="primary">mdi-eye-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
    <v-dialog 
      v-model="dialog"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-toolbar dense dark color="primary" max-height="50">
          <v-toolbar-title>{{ tituloExame }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small outlined class="mx-2" :href="openExameLink(exameShow.id)" target="_blank">
            <span>Abrir Exame</span>
          </v-btn>
          <v-btn icon @click.native="dialog = false" dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <template v-if="exameShow.formularioExameLaudo && exameShow.formularioExameLaudo.formulario && exameShow.formularioExameLaudo.formulario._id">
          <v-card-text>
            <formulario-exame-view
              :key="exameShow.formularioExameLaudo.formulario._id"
              :formularioParam="exameShow.formularioExameLaudo.formulario"
              :storageNameParam="exameShow.solicitacao.setor.unidade.storage"
            ></formulario-exame-view>
          </v-card-text>
        </template>
        <v-card-text v-else>
          <v-card-title class="pb-1">Título</v-card-title>
          <v-text-field
            v-model="exameShow.titulo"
            readonly
            rounded
            filled
          ></v-text-field>
          <v-row>
            <v-card-title class="pb-1">Relatório de consulta</v-card-title>
            <v-col cols="12">
              <div class="mb-4 conteudo-exame-html">
                <div class="pa-4 my-2" v-html="exameShow.conteudo"></div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="isExameAssinado" justify="center" class="my-0">
            <v-chip small outlined color="red" text-color="default">
              <span v-text="mensagemExameAssinado"></span>
            </v-chip>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from 'moment'
import FormularioExameView from '../../components/forms/FormularioExameView.vue';

export default {
  name: "exame-paciente-list",
  props: {
    exame: {
      type: Object,
      default: null,
      required: true
    }
  },
  components: {
    FormularioExameView,
  },
  data () {
    return {
      loading: true,
      exames: [],
      headers: [
        {
          text: "Data Exame",
          align: "center",
          sortable: true,
          value: "data",
        },
        {
          text: "Tipo Exame",
          align: "center",
          sortable: true,
          value: "tipoExame.nome",
        },
        {
          text: "Unidade",
          align: "center",
          sortable: true,
          value: "solicitacao.setor.unidade.nome",
        },
      ],
      dialog: false,
      exameShow: {},
    }
  },
  watch: {
    exame(value) {
      if (value?.id) {
        this.loadModel();
      }
    }
  },
  computed: {
    tituloExame () {
      return this.exameShow.id ? this.$_.startCase(this.exameShow.solicitacao.paciente.nome.toLowerCase()) : ""
    },
    isExameAssinado() {
      if(this.exameShow.dataAssinatura && this.exameShow.usuarioAssinatura) {
        return true;
      } 
      return false;
    },
    mensagemExameAssinado() {
      let dataAssinatura = moment(this.exameShow.dataAssinatura, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
      let mensagem = "Assinado por Dr(a) " + this.exameShow.usuarioAssinatura.nomeExibicao + " em " + dataAssinatura;

      return mensagem;
    },
  },
  methods: {
    ...mapActions(["setAlerta"]),
    loadModel () {
      const self = this;

      if(!self.exame?.solicitacao?.paciente?.cpf) {
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Paciente não possui CPF em seu cadastro!"
        })
        self.loading = !self.loading
        self.closeExamesAnteriores()
        return
      }

      self.$http.get(`/exame?max=99999&pacienteCpf=${self.exame.solicitacao.paciente.cpf}`)
        .then((response) => {
          self.loading = !self.loading
          // RETORNA TODOS OS EXAMES EXCETO O CORRENTE
          self.exames = response.data.content
            .filter(a => a.id != self.exame.id)
            .sort((a, b) => b.data - a.data);
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar exames anteriores",
          });
          self.loading = !self.loading
        });
    },
    async openExameDialog(value) {
      const self = this
      self.$http.get(`/exame/${value.id}`)
        .then(response => {
          self.exameShow = response.data
          self.dialog=!self.dialog
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Não foi possível buscar o exame!",
          })
        })
    },
    openExameLink (id) {
      return `${process.env.VUE_APP_MEZZOW_WEB_CLIENT_URL}exame/edit/${id}`
    },
    closeExamesAnteriores() {
      this.$emit("return-close")
    }
  },
  created() {
    this.loadModel()
  }
};
</script>

<style>
.conteudo-exame-html {
  border-radius: 28px; 
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
}
</style>
